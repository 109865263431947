@import "../../Variables.scss";

.divider {
  display: flex;
  .dividerTitle {
    width: auto;
    font-size: x-large;
    color: $Orange;
    padding-right: 5px;
    white-space: nowrap;
    margin: 0;
  }

  .horizontal {
    position: relative;
    width: 100%;
  }

  .horizontal div {
    position: absolute;
    bottom: 20%;
    width: 100%;
    height: 3px;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }
}
