.socialMedia {
  width: 130px;

  img {
    margin-bottom: 0.0rem !important;
    transition: 0.5s ease;
    padding-left: 10px;
    cursor: pointer;
    
  }

  img:hover {
    margin-bottom: 0.3rem !important;
    color: #ffd500 !important;
    transition: 0.5s ease;
  }
}