@import "../../Variables.scss";

@keyframes slideInFromTop {
    0% {
        transform: translatey(-100%);
    }

    100% {
        transform: translatey(0);
    }
}

@keyframes slideInFromRight {
    0% {
        transform: translatex(100%);
    }

    100% {
        transform: translatex(0%);
    }
}

.FilterActive {
    color: white !important;
    font-weight: bold;
}

.header {
    height: 12%;
    width: 100%;
    padding-top: 10px;
    background-image: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 1), rgba(0, 0, 0,0.8), rgba(0, 0, 0, 0));
    background-size: contain;
    z-index: 10;
    right: 0;

    .HeaderRightSide {
        width: 100%;
        right: 20px;
        top: 20px;

        h1 {
            white-space: nowrap;
            padding-right: 10px;
            color: $Orange;

            li a {
                text-align: right;
            }
        }

        .header-nav {
            /* This section calls the slideInFromLeft animation we defined above */
            animation: 1s slideInFromTop;
            width: 100%;
            justify-content: flex-end;

            a {
                font-size: 11px !important;
                color: rgba(255, 255, 255, 0.5);
                transition: 0.5s ease;
            }

            a:hover {
                font-size: 11px !important;
                color: rgba(255, 255, 255, 1) !important;
                cursor: pointer;
                transition: 0.5s ease;
            }
        }


    }

}

@media only screen and (max-width: 1170px) {
    .header {
        .logo {
            position: absolute;
            width: 130px;
            right: 10px;
            margin-right: 0 !important;
        }

        .HeaderRightSide {
            display: none;
        }

        .leaderboard {
            opacity: 0;
        }

    }
}

@media only screen and (max-width: 1620px) {
    .header {
        .leaderboard {
            opacity: 0;
        }

    }
}


.addimg {
    border-radius: 10px !important;
    height: 90px !important;
    width: 100%;
}

.header-p {
    font-weight: bold;
    color: rgb(245, 199, 26);
    padding-left: 5%;
    font-size: 1.5rem;
    padding-top: 4%;
    padding-left: 3.5%;
    animation: 1s ease slideInFromRight;
}