.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.StayUpdated {
  a {
    font-family: 'Abel';
    color: rgb(255, 255, 255) !important;
    font-size: 1rem !important;
    transition: 0.5s ease;
    cursor: pointer;
  }

  a:hover {
    margin-left: 0.2rem;
    color: #ffd500 !important;
    transition: 0.5s ease;
  }
}

.stay-updated-item h1,
.stay-updated-item h2,
.stay-updated-item h3,
.stay-updated-item h4,
.stay-updated-item h5,
.stay-updated-item p {
  display: inline-block
}