.blogs-size{
    height: 510px !important;
  }
  .blogs-image{
    
    height: 280px !important;
    width: 100%;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 1);
    object-fit: cover;

  }
  .blogs-title{
    color: #FFD500 !important;
  }
  .blogs-border{
    border-bottom: 3.5px solid rgba(255, 255, 255, 0.5);
  }
  .readmore{
    color:  #C1C1C1 !important;
    text-decoration: none !important;
  }
  .readmore:hover{
    text-decoration: none !important;
  }
  .red{
    color: red;
  }
  .xx-large{
    font-size: xx-large;
  }
  .screen-height{
    max-height: 50vw !important;
    &::-webkit-scrollbar{
      display: none ; 
    }
  }
  .vertical-line {
    border-right: 3.5px solid rgba(255, 255, 255, 0.5);
  }
  .pl-0{
    padding-left: 0 !important;
  }
  .expanded-blog-border{
    border-bottom: 3.5px solid rgba(255, 255, 255, 0.5);
  }
  
