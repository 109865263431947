.MainVideoPlayer {
  height: 418px;
  background: #000;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 1);
  position: relative;
}

@media only screen and (max-width: 1170px) {
  .MainVideoPlayer {
    height: 318px;
  }
}

.post-video-player {
  height: 300px;
  background: #000;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 1);
}

.responsive-iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.MainVideoPlayer #socialMedia {
  opacity: 0;
  transition: 0.5s ease;
}

.MainVideoPlayer:hover #socialMedia {
  opacity: 0.5;
  transition: 0.5s ease;
}

.img-white-filter {
  filter: brightness(0) invert(1);
}