@import"../Post/Post.scss";
@import "../../Variables.scss";

.videos {

  // background: grey;
  h1 {
    font-size: large;
    color: white;
  }

  h2 {
    font-size: medium;
    color: white;
  }

  h3 {
    font-size: small;
    color: white;
  }
}

.Tags {
  div {
    width: auto;
    padding-right: 10px;
  }
}

.MainVideoDescription {
  padding-top: 20px;
  padding-bottom: 15px;

  h3 {
    color: $Grey;
    margin: 0;
  }

  h1 {
    color: $Orange;
    font-size: 1.5rem;
  }

  h2 {
    color: white;
    font-size: 1.0rem;
    font-family: 'Abel';
  }
}